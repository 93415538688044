export default function parseTextWithParagraphs(text: string) {
    const paragraphs = [];

  // Regular expression to match <p>...</p> patterns
  const paragraphRegex = /<p>(.*?)<\/p>/g;
  
  // Split the text using the regex pattern
  let lastIndex = 0;
  let match;
  
  while ((match = paragraphRegex.exec(text)) !== null) {
    // Push the preceding text as a plain string
    const precedingText = text.substring(lastIndex, match.index);
    if (precedingText) {
      paragraphs.push(precedingText);
    }

    // Push the matched <p>...</p> as a paragraph element
    const paragraphContent = match[1]; // Content inside <p>...</p>
    paragraphs.push(<p>{paragraphContent}</p>);

    // Update the lastIndex to the end of the current match
    lastIndex = match.index + match[0].length;
  }

  // Push any remaining text after the last match
  const remainingText = text.substring(lastIndex);
  if (remainingText) {
    paragraphs.push(remainingText);
  }

  return paragraphs;
}