import styles from './ProductListPage.module.scss';
import ProductGrid from '../../components/ProductView/ProductGrid';
import { useEffect, useState } from 'react';
import axios from 'axios';
import backendLink from 'src/gravtech';

export default function ProductListPage() {
    const [productsData, setProductsData] = useState([]);

    useEffect(() => {
        axios.get(`${backendLink}products.php`)
        .then(response => {
            setProductsData(response.data.produtos);
        })
            .catch (error => {
            console.error('Error fetching data:', error);
          })
        }, []);
    
    return(
        <section>
            <ProductGrid products={productsData}/>
        </section>
    )
}