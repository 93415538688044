import styles from './ContactMe.module.scss';
import axios from 'axios';
import { useEffect, useState } from 'react';
import backendLink from 'src/gravtech';

interface ContactMeProps {
  context: 'main' | 'product';
  productName?: string;
  productId?: number;
}
export default function ContactMe({ context, productName, productId }: ContactMeProps) {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [id, setId] = useState(0);
  const [requestData, setRequestData] = useState({});

  useEffect(() => {
    if(id === 0) {
      setRequestData({
        id: id,
        nome: name,
        email: email,
        fone: phone, 
        mensagem: message
      });
    } else if(id === 1) {
      setRequestData({
        id: id,
        nome: name,
        email: email,
        fone: phone, 
        mensagem: message,
        idprod: productId,
        nomeprod: productName,
      });
    } 
  }, [id, name, email, phone, message, productId, productName])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if(name === '' || email === '' || message === '' || phone === '') {
      alert('Preencha todos os campos');
      return;
    }

    if(context === 'main') {
      setId(0);
    } else if (context === 'product'){
      setId(1);
    }

    const apiUrl = `${backendLink}/email.php`;    
    
    try {
      const response = await axios.post(apiUrl, null, { params: requestData });
      console.log('Email sent successfully:', response.data);
    } catch (error) {
      console.error('Error sending email:', error);
    }
   
    setName('');
    setEmail('');
    setMessage('');
    setPhone('');

  };

  return (
    <section id='contact' className={styles.box}>
      <h1 className={styles.title}>Entre em contato!</h1>
      <h2 className={styles.phrase}>Perguntas? Vamos conversar</h2>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.form__iputContainers}>
          <label className={styles.form__label} htmlFor="name">Nome</label>
          <input 
            className={styles.form__input} 
            type="text" 
            id="name" 
            name='name' 
            value={name}
            placeholder='Nome'
            onChange={(event) => setName(event.target.value)} 
          />
          <label className={styles.form__label} htmlFor="email">'E-mail'</label>
          <input 
            className={styles.form__input} 
            type="email" 
            id="email" 
            name='email' 
            value={email}
            placeholder='E-mail'
            onChange={(event) => setEmail(event.target.value)} 
          />
          <input 
            className={styles.form__input} 
            type="text" 
            id="phone" 
            name='phone' 
            value={phone}
            placeholder='Telefone'
            onChange={(event) => setPhone(event.target.value)} 
          />
        </div>
        <label className={styles.form__label} htmlFor="message">'Deixe sua mensagem'</label>
        <div className={styles.form__textareaContainer}>
          <textarea 
            className={styles.form__textarea} 
            id="message" 
            value={message}
            placeholder='Escreva aqui sua mensagem'
            onChange={(event) => setMessage(event.target.value)} 
          />
        </div>
        <button className={styles.form__button} type="submit">Enviar</button>
      </form>
    </section>
  );
}