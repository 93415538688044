import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Institucional from 'components/Institucional';
import ContactMe from 'components/ContactMe';
import ProductView from 'components/ProductView';
import backendLink from 'src/gravtech';
import Banner from 'components/Banner';

export default function MainPage() {
    const [productsData, setProductsData] = useState([]);

    useEffect(() => {
        axios.get(`${backendLink}products.php`)
        .then(response => {
            setProductsData(response.data.produtos);
        })
            .catch (error => {
            console.error('Error fetching data:', error);
          })
        }, []);

    return (
        <main>
            <Banner />
            <ProductView products={productsData}/>    
            <Institucional />
            <ContactMe context='main'/>
        </main>
    );
}