import React, { useState } from 'react';
import styles from './Navbar.module.scss';
import { Link } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'; // Import ScrollLink and scroll
import Logo from './assets/logo.png';

export default function Navbar() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 600;

  const handleResizeWindow = () => setWidth(window.innerWidth);
  const closeHamburguer = () => setIsMenuOpen(false);
  window.addEventListener('resize', handleResizeWindow);
  window.addEventListener('resize', closeHamburguer);


  const handleMenuClick = () => {
    if(width < breakpoint) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  

  const scrollTo = (elementId: number) => {
    scroll.scrollTo(elementId, {
      smooth: 'easeInOutQuart', // You can adjust the smooth scrolling effect
    });
    closeHamburguer(); // Close the menu after clicking
  };

  const topics = [{
    label: 'Home',
    to: '/'
  }, {
    label: 'Quem somos',
    to: 'about'
  } ,{
    label: 'Produtos',
    to: 'products'
  } , {
    label: 'Contato',
    to: 'contact'
  }];

  return (
    <header>
      <nav className={styles.navbar}>
        <Link to="/" className={styles.navbar__link}><img className={styles.navbar__logo} src={Logo} alt="logo" /></Link>
        <button
          className={styles.navbar__hamburger + (isMenuOpen ? ' ' + styles.open : '')}
          onClick={handleMenuClick}
        >
          <span className={styles['navbar__hamburger-line']}></span>
          <span className={styles['navbar__hamburger-line']}></span>
          <span className={styles['navbar__hamburger-line']}></span>
        </button>
        <ul className={styles.navbar__topics + (isMenuOpen ? ' ' + styles.open : '')}>
          {topics.map((topic, index) => (
            <li key={index} className={styles.navbar__topic}>
            {topic.label === 'Home' ? (
              <RouterLink className={styles.navbar__topic} to={topic.to}>{topic.label}</RouterLink>
            ) : (
              <ScrollLink className={styles.navbar__topic} to={topic.to} spy={true} smooth={true} duration={500} onClick={() => closeHamburguer()}>
                {topic.label}
              </ScrollLink>
            )}
          </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}
