
import Navbar from 'components/Navbar';
import './App.css';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from 'pages/MainPage/MainPage';
import ProductDetail from 'pages/ProductPage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProductListPage from 'pages/ProductListPage';



function App() {
  return (
    <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/products' element={<ProductListPage />} />
          <Route path="/products/:id" element={<ProductDetail />} />
        </Routes>
        <Footer />
    </Router>
  );
}

export default App;
