import styles from './ProductSlider.module.scss';
import ProductCard from '../ProductCard';
import Slider from 'react-slick';
import ProductCardProps from 'interfaces/Product/Product';
import Arrow from './assets/arrow.png';
import React, { useEffect, useState } from 'react';


interface ProductViewProps {
    products: ProductCardProps[];
}

const CustomNextArrow: React.FC<any> = (props) => (
    <div className={styles.custom_right_arrow} onClick={props.onClick}>
      <img src={Arrow} alt="right-arrow" />
    </div>
);

const CustomPrevArrow: React.FC<any> = (props) => (
    <div className={styles.custom_left_arrow} onClick={props.onClick}>
      <img src={Arrow} alt="left-arrow" />
    </div>
);
export default function ProductSlider({ products }: ProductViewProps) {
  const [sliderKey, setSliderKey] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
    
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true, // Center the active slide
    nextArrow: <CustomNextArrow />, 
    prevArrow: <CustomPrevArrow />, 
    className: styles['custom-slick-slider'], 
    responsive: [
      {
        breakpoint: 600, 
        settings: {
          slidesToShow: 1, 
        },
      },
    ],
    afterChange: (index: number) => {
      setCurrentSlide(index);
    },
  };


  useEffect(() => {
    // Reinitialize the slider when the component mounts
    setSliderKey((prevKey) => prevKey + 1);

    const handleResize = () => {
      // Reinitialize the slider when the window is resized
      setSliderKey((prevKey) => prevKey + 1);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Slider {...settings}>
      {products.map((product, index) => (
        <div key={product.id} >
          <ProductCard product={product} isActive={index === currentSlide} isInSlider />
        </div>
      ))}
    </Slider>
  );
}
  
