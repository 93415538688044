import React, { useState, useEffect } from 'react';
import styles from './ProductView.module.scss';
import ProductGrid from './ProductGrid';
import ProductSlider from './ProductSlider';
import ProductCardProps from 'interfaces/Product/Product';
import { Link } from 'react-router-dom';

interface ProductViewProps {
    products: ProductCardProps[];
}

export default function ProductView({ products }: ProductViewProps) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const breakpoint = 1099.98; // Set your desired breakpoint here

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setWindowWidth(window.innerWidth);
      }, 100); // Adjust the delay as needed
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Check if products data is available
  if (!products || products.length === 0) {
    return <div>Loading...</div>; // or any loading indicator you prefer
  }

  console.log(products)

  const filterProducts = products.filter(product => product.carousel === "yes")

  return (
    <section id='products' className={styles.product_view}>
      {windowWidth < breakpoint ? (
        <ProductSlider products={filterProducts}/>
      ) : (
        <ProductGrid products={filterProducts}/>
      )}
      <Link to='/products' className={styles.product_view__link}>Ver todos os produtos</Link>
    </section>
  );
}
