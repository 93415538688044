import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductCardProps from 'interfaces/Product/Product';
import styles from './ProductPage.module.scss';
import backendLink from 'src/gravtech';
import parseTextWithParagraphs from 'utils/parseTextWithParagraphs';


import { useParams } from 'react-router-dom';
import ContactMe from 'components/ContactMe';

export default function ProductDetail() {
  const { id } = useParams(); // This gets the 'id' parameter from the URL
  const [product, setProduct] = useState<ProductCardProps | null>(null);

  useEffect(() => {
    axios.get(`${backendLink}products.php?id=${id}`)
    .then(response => {
      const productsArray = response.data.produto;
      if (productsArray.length > 0) {
        const product = productsArray[0];
        setProduct(product);
      }
    })
        .catch (error => {
        console.error('Error fetching data:', error);
      })
    }, []);


  if (!product) {
    return <div>Loading...</div>;
  } 

  const imagePath = `/images/${product.image.trim()}`;

  const parsedLongDescription = parseTextWithParagraphs(product.longDescription);

  return (
    <main>
        <img className={styles.img} src={imagePath } alt={product.name} />
        <div className={styles.info}>
          <h2 className={styles.info__name}>{product.name}</h2>
          <p className={styles.info__long_description}>{parsedLongDescription}</p>
        </div>
        <ContactMe context='product' productName={product.name} productId={product.id}/>
    </main>
  );
}
