import { Link } from 'react-router-dom';
import styles from './ProductCard.module.scss';
import ProductCardProps from 'interfaces/Product/Product';
import classNames from 'classnames';

interface ProductCardType {
  product: ProductCardProps
  isActive?: boolean;
  isInSlider?: boolean;
}

export default function ProductCard({ product , isActive = false, isInSlider = false}: ProductCardType) {
  const imagePath = `/images/${product.image.trim()}`;
  const cardClasses = classNames(
    styles.product_card, 
    { [styles.active]: isActive && isInSlider},
    { [styles.inactive]: !isActive && isInSlider}
  );

    return (
      <div className={cardClasses}>
        <div className={styles.product_card__imagebox}>
          <img src={imagePath} alt={product.name} className={styles.product_card__imagebox__image} /> 
        </div>
        <div className={styles.product_card__body}>
          <h2 className={styles.product_card__body__name}>{product.name}</h2>
          <p className={styles.product_card__body__shortdescription}>{product.shortDescription}</p>
          <div className={styles.product_card__body__linkbox}>
            <Link to={`/products/${product.id}`} className={styles.product_card__body__linkbox__link}>
              <span className={styles.product_card__body__linkbox__link__text}>Saiba mais</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }