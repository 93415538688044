import styles from './Quadro.module.scss';

interface QuadroProps {
    title: string;
    text: React.ReactNode;
}

export default function Quadro({ title, text }: QuadroProps) {
    return (
        <div className={styles.quadro}>
            <h3 className={styles.quadro__title}>{title}</h3>
            <p className={styles.quadro__text}>{text}</p>
        </div>
    );
}