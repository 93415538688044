import styles from './ProductGrid.module.scss';
import ProductCard from '../ProductCard';
import ProductCardProps from 'interfaces/Product/Product';

interface ProductViewProps {
    products: ProductCardProps[];
}

export default function ProductView({ products }: ProductViewProps) {
    return (
        <div className={styles.product_view}>
            {products.map(product => (
                <ProductCard key={product.id} product={product} />
            ))}
        </div>
    );
}