import styles from './Footer.module.scss';
import linkedinLogo from './assets/linkedin-white-logo.png';
import whatsappLogo from './assets/whatsapp-logo.png';
import facebookLogo from './assets/facebook-logo.png';

export default function Footer() {

  return(
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__container__content}>
          <a href="www.linedin.com.br">
            <img className={styles.footer__container__content__img} src={linkedinLogo} alt="likedin" />
          </a>
          <a href="https://wa.me/+5514991316733">
            <img className={styles.footer__container__content__img} src={whatsappLogo} alt="whatsapp" />
          </a>
          <a href="www.facebook.com.br">
            <img className={styles.footer__container__content__img} src={facebookLogo} alt="whatsapp" />
          </a>
        </div>
      </div>
    </footer>
  );
}