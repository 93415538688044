import styles from './Institucional.module.scss';
import Quadro from './Quadro';
import axios from 'axios';
import parseTextWithParagraphs from 'utils/parseTextWithParagraphs';
import React, { useEffect , useState } from 'react';

interface TextosData {
  id_desc: string;
  corpo: string;
  visao: string;
  misao: string;
  valor: string;
  banner1: string;
  banner1Enable: string;
  banner2: string;
  banner2Enable: string;
  banner3: string;
  banner3Enable: string;
}

interface ApiResponse {
  textos: TextosData[];
}
export default function Institucional() {
  const [data, setData] = useState<ApiResponse>({ textos: [] });


  useEffect(() => {
    axios.get('/bdsite/description.php?id=all')
    .then(response => {
        setData(response.data);
    })
        .catch (error => {
        console.error('Error fetching data:', error);
      })
    }, []);

    if (data === null) {
      // Data is still being fetched, you can show a loading indicator or return null
      return null;
    }

    const quadros = [{
        title: 'Visão',
        text: parseTextWithParagraphs(data.textos && data.textos.length > 0 ? data.textos[0].visao : '')
      }, {
        title: 'Missão',
        text: parseTextWithParagraphs(data.textos && data.textos.length > 0 ? data.textos[0].misao : '')
      } ,{
        title: 'Valores',
        text: parseTextWithParagraphs(data.textos && data.textos.length > 0 ? data.textos[0].valor : '')
      }];

      const bodyText = parseTextWithParagraphs(data.textos && data.textos.length > 0 ? data.textos[0].corpo : '');

    return (
        <section id='about' className={styles.institucional}>
            <h2 className={styles.institucional__title}>Sobre nós</h2>
            <p className={styles.institucional__body}>{bodyText}</p>
            <ul className={styles.institucional__quadros}>
                {quadros.map((quadro, index) => (
                <li key={index} className={styles.institucional__quadros__quadro}>
                    <Quadro title={quadro.title} text={quadro.text} />
                </li>
                ))}
            </ul>
        </section>
    );
}