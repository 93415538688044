
import React, { useEffect, useState } from 'react';
import styles from './Banner.module.scss';
import axios from 'axios';
import Slider from 'react-slick';

interface TextosData {
    id_desc: string;
    corpo: string;
    visao: string;
    misao: string;
    valor: string;
    banner1: string;
    banner1Enable: string;
    banner2: string;
    banner2Enable: string;
    banner3: string;
    banner3Enable: string;
  }
  
  interface ApiResponse {
    textos: TextosData[];
  }
export default function Banner() {
  const [data, setData] = useState<ApiResponse>({ textos: [] });

  const CustomNextArrow: React.FC<any> = (props) => (
    <div className={styles.custom_right_arrow} ></div>
);

  const settings = {
    dots: true,
    arrow: false,
    nextArrow: <CustomNextArrow />,
    appendDots: (dots: React.ReactNode) => (
      <div
        style={{
          position: 'absolute',
          bottom: '10px', // Adjust the bottom value as needed
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ul style={{ padding: '0', margin: '0', listStyle: 'none' }}>{dots}</ul>
      </div>
    ),
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    className: styles['custom-slick-slider'], 
    responsive: [
      {
        breakpoint: 600,

        settings: {
          slidesToShow: 1,
        },
      },
    ],
    autoplay: true, 
    autoplaySpeed: 5000,
  };
  
    useEffect(() => {
        axios.get('/bdsite/description.php?id=all')
        .then(response => {
            setData(response.data);
        })
            .catch (error => {
            console.error('Error fetching data:', error);
        })
    }, []);

    if (data === null) {
    // Data is still being fetched, you can show a loading indicator or return null
    return null;
    }

    console.log(data.textos)

    const banners = [{
      title: 'Banner 1',
      text: data.textos && data.textos.length > 0 ? data.textos[0].banner1 : '',
      enable: data.textos && data.textos.length > 0 ? data.textos[0].banner1Enable : '',
    }, {
      title: 'Banner 2',
      text: data.textos && data.textos.length > 0 ? data.textos[0].banner2 : '',
      enable: data.textos && data.textos.length > 0 ? data.textos[0].banner2Enable : '',
    } ,{
      title: 'Banner 3',
      text: data.textos && data.textos.length > 0 ? data.textos[0].banner3 : '',
      enable: data.textos && data.textos.length > 0 ? data.textos[0].banner3Enable : '',
    }];

    const enabledBanners = banners.filter((item) => item.enable.toLowerCase() === 'yes');

  return (
    <Slider {...settings}>
      {enabledBanners.map((item, index) => (
        <div className={styles.banner} key={index} >
          <div 
            className={styles.bannerImage}
            style={
            {
              backgroundImage: `url(/images/${item.text.trim()})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }
          }
          ></div>
        </div>
      ))}
    </Slider>
  );
};








